.news {
  width: 35vw;
  height: 35vh;
  margin: 0 !important;
  backdrop-filter: blur(6px);
  background: rgba(18, 18, 18, .5) !important;
  border-radius: 10px !important;
}

.news .cardContent {
  padding: 0 !important;
  height: 100%;
}

.news .cardContent .MuiTab-root {
  font-size: 1.6vh;
}

.tabPanel {
  padding: 10px 20px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 90%;
}

.tabPanel::-webkit-scrollbar {
  width: 8px;
  appearance: none;
}

.tabPanel::-webkit-scrollbar-thumb {
  border-radius: 32px;
  border: 1px solid;
  background-color: rgba(18, 18, 18, .5);
}

.MuiButtonBase-root,
.MuiTabs-root {
  height: 35px !important;
  min-height: 35px !important;
}
