.Home {
    background-color: #797572;
    background-image:
            linear-gradient(-173deg, rgba(255,255,255,0.20) 0%, #000000 100%),
            linear-gradient(72deg, rgba(255,255,255,0.25) 25%, rgba(0,0,0,0.25) 100%),
            radial-gradient(47% 102%, rgba(255,255,255,0.50) 0%, rgba(21,24,32,0.60) 120%);
    background-blend-mode: multiply;
    height: 100vh;
    margin: 0 !important;
    padding: 5vh;
}