.codingActivity {
  width: 100%;
  height: 35vh;
  margin: 0 !important;
  backdrop-filter: blur(6px);
  background: rgba(18, 18, 18, .5) !important;
  border-radius: 10px !important;
}

.codingActivity .cardContent {
  padding: 0 !important;
  height: 100%;
}

.codingActivity .title {
  font-size: 2.4vh !important;
}

.codingActivity .content {
  margin: 0 !important;
  margin-top: 1vh !important;
  font-size: 1.8vh;
  text-align: center;
}

.codingActivity .img {
  height: 90%;
  width: 100% !important;
}