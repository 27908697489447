.aws {
  width: 100%;
  height: 35vh;
  margin: 0 !important;
  backdrop-filter: blur(6px);
  background: rgba(18, 18, 18, .5) !important;
  border-radius: 10px !important;
}

.aws .cardContent {
  padding: 0 !important;
  margin-left: 1.2vw;
  margin-right: 1.2vw;
}

.aws .button {
  padding: 1vh !important;
}

.aws .moneyIcon {
  width: 1.4vw;
}

.aws .freshIcon {
  width: 1.4vw;
}

.aws .billing {
  display: flex;
  padding: 1vh;
  align-items: center;
}

.aws .title {
  font-size: 2.4vh !important;
}

.aws .content {
  width: 100%;
  font-size: 1.8vh;
  margin: 0 !important;
}