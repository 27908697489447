.search {
  width: 100%;
  height: 35vh;
  margin: 0 !important;
  backdrop-filter: blur(6px);
  background: rgba(18, 18, 18, .5) !important;
  border-radius: 10px !important;
}

.search .cardContent {
  padding: 0 !important;
  margin: 10px;
}

.search .searchBar {
  display: flex;
}

.search .button {
  padding: 1vh !important;
}

.search .inputBase {
  width: 85%;
  font-size: 1.2vw;
}

.search .googleIcon {
  width: 1.4vw;
}

.search .searchIcon {
  width: 1.4vw;
}

.itemText .MuiTypography-root {
  font-size: 1.6vh !important;
}


